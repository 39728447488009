<template>
  <div class="box">
    <div class="filter">
      <div class="total">
        <div class="tip">
          Total Conferences
        </div>
        <div class="num">
          {{ total }}
        </div>
      </div>
      <div class="search-box">
        <div class="input-box">
          <input
            v-on:keyup.enter="handleSearch"
            v-model="filter.search"
            type="text"
            placeholder="Search for a conference..."
          />
        </div>
        <div class="btn" @click="handleSearch">Search</div>
      </div>
    </div>

    <Table :loading="loading" :columns="columns" :data="list">
      <template slot-scope="{ row: item }" slot="state">
        <div v-if="item.pivot.status === 1">
          <Tag color="success">approved</Tag>
        </div>
        <div v-if="item.pivot.status === -1">
          <Tag color="error">rejected</Tag>
        </div>
        <div v-if="item.pivot.status === 0">
          <Tag>pending</Tag>
        </div>
      </template>
    </Table>

    <div class="page">
      <Page
        :page-size="limit"
        :page-size-opts="[5, 10, 15, 20]"
        :total="total"
        show-sizer
        show-elevator
        show-total
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import api from "@/api";
const { _queryMyConferenceList } = api;

export default {
  name: "documents",

  computed: {
    ...mapState("user", ["user_id", "is_administrator"])
  },

  created() {
    this.queryMyConferenceList();
  },

  data() {
    return {
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      },

      columns: [
        {
          title: "NAME OF CONFENCE",
          key: "title"
        },
        {
          title: "HOST",
          key: "host_name"
        },
        {
          title: "DATE(S)",
          key: "start_date",
          render: (h, params) => {
            const start = moment
              .utc(params.row.start_date)
              .local()
              .format("MMM D,YYYY");

            const end = moment
              .utc(params.row.end_date)
              .local()
              .format("MMM D,YYYY");

            return h("span", `${start}-${end}`);
          }
        },
        {
          title: "STATUS",
          key: "state",
          slot: "state"
        }
      ]
    };
  },

  methods: {
    handleSearch() {
      this.page = 1;
      this.queryMyConferenceList();
    },

    queryMyConferenceList() {
      this.loading = true;

      _queryMyConferenceList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryMyConferenceList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMyConferenceList();
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .filter {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .total {
      color: #333;
      .tip {
      }
      .num {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .search-box {
      flex: 1;
      height: 40px;
      display: flex;
      margin: 0 20px;
      .input-box {
        position: relative;
        flex: 1;
        height: 100%;
        background: #fff;
        line-height: initial;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          background: transparent;
          border: none;
          color: #444;
          caret-color: #888;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
        }
      }
      .btn {
        cursor: pointer;
        background: #fff;
        color: @primary-color;
        border: 1px solid @primary-color;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
      }
    }
  }
  .page {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
